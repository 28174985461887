<template>
    <div class="lineManagement">
        <jy-query ref="checkForm" :model="formInline">
            <jy-query-item label="线路编号:" prop="routeNu">
                <el-input v-model="formInline.routeNu" placeholder="请输入"></el-input>
            </jy-query-item>
            <jy-query-item label="线路名称:" prop="cName">
                <el-input v-model="formInline.cName" placeholder="请输入"></el-input>
            </jy-query-item>
            <jy-query-item label="所属机构:" prop="orgNa">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="formInline.orgNa"></el-input>
                </div>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('scheduleBasicRouteList')">查询</el-button>
                <el-button type="primary" @click="resetForm('checkForm')" v-if="btnexist('scheduleBasicRouteReset')">重置</el-button>
                <!-- <el-button type="primary" @click="onexport">导出</el-button> -->
                <el-row>
                    <el-button type="primary" @click="add" v-if="btnexist('scheduleBasicRouteInsert')">新增</el-button>
                    <el-button type="primary" @click="batchdelete" v-if="btnexist('scheduleBasicRouteBatchDelete')">批量删除</el-button>
                    <el-button type="primary" @click="exportList" v-if="btnexist('scheduleBasicRouteExport')">导出</el-button>
                    <!-- <el-button type="primary" @click="importFun" v-if="btnexist('scheduleBasicRouteSiteImport')">导入</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <jy-table ref="jyTable" :data="lists" @select-all="selectallchange" @select="selectchange">
            <jy-table-column type="index" width="60" label="序号" fixed="left"> </jy-table-column>
            <jy-table-column type="selection" width="40" fixed="left"> </jy-table-column>
            <jy-table-column label="线路编号" min-width="100" fixed="left">
                <template slot-scope="scope">
                    {{scope.row.routeInfoVO.routeNu}}
                </template>
            </jy-table-column>
            <jy-table-column label="线路名称" min-width="100" fixed="left">
                <template slot-scope="scope">
                    {{scope.row.routeInfoVO.cName}}
                </template>
            </jy-table-column>
            <jy-table-column prop="orgNa" label="所属机构" min-width="200"> </jy-table-column>
            <jy-table-column label="线路类型" min-width="100">
                <template slot-scope="scope">
                    {{scope.row.routeInfoVO.rType|xltype}}
                </template>
            </jy-table-column>
            <jy-table-column label="所属城市" min-width="100">
                <template slot-scope="scope">
                    {{scope.row.routeInfoVO.bCity}}
                </template>
            </jy-table-column>
            <jy-table-column label="早高峰开始时间" min-width="100">
                <template slot-scope="scope">
                    {{scope.row.routeInfoVO.mornPeakS}}
                </template>
            </jy-table-column>
            <jy-table-column label="早高峰结束时间" min-width="100">
                <template slot-scope="scope">
                    {{scope.row.routeInfoVO.mornPeakE}}
                </template>
            </jy-table-column>
            <jy-table-column label="晚高峰开始时间" min-width="100">
                <template slot-scope="scope">
                    {{scope.row.routeInfoVO.nightPeakS}}
                </template>
            </jy-table-column>
            <jy-table-column label="晚高峰结束时间" min-width="100">
                <template slot-scope="scope">
                    {{scope.row.routeInfoVO.nightPeakE}}
                </template>
            </jy-table-column>
            <!-- <jy-table-column prop="createTime" label="创建日期" min-width="170"></jy-table-column> -->
            <jy-table-column label="上行场站id" min-width="200">
                <template slot-scope="scope">
                    {{scope.row.routeInfoVO.uStationId}}
                </template>
            </jy-table-column>
            <jy-table-column prop="uTime" label="上行单程时间" min-width="100">
                <template slot-scope="scope">
                    {{scope.row.routeInfoVO.uTime}}
                </template>
            </jy-table-column>
            <jy-table-column prop="uMileage" label="上行单程距离" min-width="100">
                <template slot-scope="scope">
                    {{scope.row.routeInfoVO.uMileage}}
                </template>
            </jy-table-column>
            <jy-table-column prop="uMinDI" label="上行最小发车间隔" min-width="140">
                <template slot-scope="scope">
                    {{scope.row.routeInfoVO.uMinDI}}
                </template>
            </jy-table-column>
            <jy-table-column prop="uMaxDI" label="上行最大发车间隔" min-width="140">
                <template slot-scope="scope">
                    {{scope.row.routeInfoVO.uMaxDI}}
                </template>
            </jy-table-column>
            <jy-table-column prop="dStationId" label="下行场站id" min-width="200">
                <template slot-scope="scope">
                    {{scope.row.routeInfoVO.dStationId}}
                </template>
            </jy-table-column>
            <jy-table-column prop="dMileage" label="下行单程距离" min-width="100">
                <template slot-scope="scope">
                    {{scope.row.routeInfoVO.dMileage}}
                </template>
            </jy-table-column>
            <jy-table-column prop="dMinDI" label="下行最小发车间隔" min-width="140">
                <template slot-scope="scope">
                    {{scope.row.routeInfoVO.dMinDI}}
                </template>
            </jy-table-column>
            <jy-table-column prop="dMaxDI" label="下行最大发车间隔" min-width="140">
                <template slot-scope="scope">
                    {{scope.row.routeInfoVO.dMaxDI}}
                </template>
            </jy-table-column>
            <jy-table-column prop="crossDis" label="串车距离" min-width="100">
                <template slot-scope="scope">
                    {{scope.row.routeInfoVO.crossDis}}
                </template>
            </jy-table-column>
            <jy-table-column prop="largeDis" label="大间隔距离" min-width="100">
                <template slot-scope="scope">
                    {{scope.row.routeInfoVO.largeDis}}
                </template>
            </jy-table-column>
            <jy-table-column label="售票方式" min-width="100">
                <template slot-scope="scope">{{ scope.row.routeInfoVO.ticketM | ticketM }}</template>
            </jy-table-column>
            <jy-table-column  label="运营类型" min-width="100">
                <template slot-scope="scope">{{ scope.row.routeInfoVO.opType | opType }}</template>
            </jy-table-column>
            <jy-table-column  label="线路状态" min-width="100">
                <template slot-scope="scope">{{ scope.row.routeInfoVO.state | state }}</template>
            </jy-table-column>
            <jy-table-column  label="线路总长" min-width="100">
                <template slot-scope="scope">{{ scope.row.routeInfoVO.routeLen}}</template>
            </jy-table-column>
            <jy-table-column prop="remark" label="备注" min-width="200">
                <template slot-scope="scope">
                    {{scope.row.routeInfoVO.remark}}
                </template>
            </jy-table-column>
            <jy-operate v-bind:list="operateList" width="240"></jy-operate>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
        </jy-pagination>
        <add-dialog ref="dialog" @updateList="getlist"></add-dialog>
        <add-timedialog ref="timedialog" @updateList="getlist"></add-timedialog>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
        <jy-import ref="jyImport" :headers="uploadHeaders" :action="action" @downloadTemplate="downloadTemplate" @getElxData="getElxData" @confirm="confirm" :successLength="correctCount" :errList="listError" :status.sync="importStatus"></jy-import>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'
import adddialog from '@/components/pages/admin/basicInformation/lineManagement/addline'
import addtimedialog from '@/components/pages/admin/basicInformation/lineManagement/addhistorytime'
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
import {btnMixins} from '@/common/js/button.mixin'
export default {
    data() {
        return {
            formInline: {
                routeNu: '',
                cName: '',
                orgId: '',
                orgNa: '',
            },
            lists: [],
            // 批量删除
            batchdeleteList: [],
            // 分页
            pageIndex: 1,
            total: 100,
            pageSize: 10,
            //  操作
            operateList: [],

            //导入
            uploadHeaders: {
                token: this.$util.getStor('token'),
            },
            action: '/IntelligentArrangeSystem/baseinforoute/importRoute',
            importId: '',
            listError: [],
            correctCount: '',
            errorCount: '',

            importStatus: 1,

            btnMenuId:'scheduleBasicRoute',
        }
    },
    mixins:[btnMixins],
    components: {
        'add-dialog': adddialog,
        'add-timedialog': addtimedialog,
        institutionsTree,
    },
    created() {
        this.getlist()
        this.setOperateList()
    },
    filters: {
        xltype(val) {
            if (val == '0') {
                return '上下行'
            } else {
                return '环形'
            }
        },
        ticketM(val) {
            if (val == '0') {
                return '人工售票'
            } else {
                return '无人售票'
            }
        },
        opType(val) {
            if (val == '0') {
                return '非运营'
            } else {
                return '运营'
            }
        },
        state(val) {
            if (val == '0') {
                return '启用'
            } else {
                return '禁用'
            }
        }
    },
    methods: {
        ...mapMutations(['resetTree']),
        setOperateList() {
            let l = [
                {
                    name: '修改',
                    icon: 'el-icon-edit-outline',
                    fun: this.handleEdit,
                    isShow:()=>{
                        return this.btnexist('scheduleBasicRouteUpdate')
                    },
                },
                {
                    name: '删除',
                    icon: 'el-icon-delete',
                    fun: this.handleDelete,
                    isShow:()=>{
                        return this.btnexist('scheduleBasicRouteDelete')
                    },
                },
                // {
                //     name: '设定首末班时间',
                //     icon: 'el-icon-view',
                //     fun: this.handlefirstlast,
                //     isShow:()=>{
                //         return this.btnexist('setTime')
                //     },
                // },
            ]
            this.operateList = l
        },
        getlist() {
            let url = '/route/info/querypage'
            let option = {
                ...this.formInline,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            this.$http.post(url, option).then(({ detail }) => {
                let list = detail.list.map(item=>{
                    return {
                        ...item,
                        ...item.sysOrg,
                        ...item.routeInfo,
                    }
                })
                console.log(list)
                this.lists = list
                this.total = detail.total
            })
        },
        // 所属机构
        addInstitutions(data) {
            console.log(data)
            this.formInline.orgId = data.orgId
            this.formInline.orgNa = data.orgNa
        },
        // 组织
        selectInstitutions() {
            this.$refs.institutions.init()
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getlist()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.formInline.orgId = ''
            this.oncheck()
        },
        // 导出
        onexport() {
            console.log('导出')
            let url = "/route/info/excelRouteInfo";
				let params = {
                    routeNu: this.formInline.routeNu,
                    orgId: this.formInline.orgId,
                    cName: this.formInline.cName
				};
                console.log(params)
                this.$http.post(url, params, { isExcel: true }).then((res) => {
                    if (res.status == 200) {
						this.$util.funDownload(res.data, "公交线路");
					} else {
						this.$message({
							message: "导出失败",
							type: "warning",
						});
					}
                });
        },
        // 新增
        add() {
            this.$refs.dialog.init()
            console.log('新增')
        },
        // 批量删除
        batchdelete() {
            if (!this.batchdeleteList.length) {
                this.$message({
                    type: 'warning',
                    message: '请先选择删除项',
                })
                return false
            }
            this.$confirm('是否删除选中线路?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let option = {
                        routeIds:this.batchdeleteList.map(item=>item.routeInfoVO.routeId).join(',')
                    }
                    this.$http.post('/route/info/batchDel', option).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        })
                        this.resetTree()
                        this.getlist()
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        // 批量导入
        batchimport() {},

        selectchange(selection) {
            console.log(selection)
            this.batchdeleteList = selection
        },
        selectallchange(selection) {
            console.log(selection)
            this.batchdeleteList = selection
        },

        // 删除
        handleDelete(row) {
            console.log(row)
            this.$confirm('是否删除该线路?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$http.post('/route/info/del', {routeId:row.routeInfoVO.routeId}).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        })
                        this.resetTree()
                        this.getlist()
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
            //   console.log(row)
        },
        // 修改
        handleEdit(row) {
            this.$refs.dialog.init(row, 'update')
            console.log('编辑')
        },
        // 设定首末班时间
        handlefirstlast(row) {
            this.$refs.timedialog.init(row)
        },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.getlist()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.pageIndex = val
            this.getlist()
        },

        //导出
        exportList() {
            let url = '/baseinforoute/exportRoute'
            let option = {
                ...this.formInline,
            }
            this.$http
                .post(url, option, { type: 'format', isExcel: true })
                .then(res => {
                    if (res.status == 200) {
                        this.$util.funDownload(res.data, '线路信息')
                    } else {
						this.$message({
							message: "导出失败",
							type: "warning",
						});
					}
                })
        },
        //导入
        importFun() {
            this.$refs.jyImport.init()
        },
        downloadTemplate() {
            let url = '/baseinforoute/importTemplate'
            this.$http
                .post(url, {}, { type: 'format', isExcel: true })
                .then(res => {
                    this.$util.funDownload(res.data, '线路模板')
                })
        },
        getElxData(data) {
            console.log(data.detail)
            this.importId = data.detail.importId
            let url = '/baseinforoute/importRouteCheck'
            let params = {
                importId: data.detail.importId,
            }
            this.$http.post(url, params).then(data => {
                let { detail } = data
                this.listError = detail.listError
                this.correctCount = detail.correctCount
                this.errorCount = detail.errorCount
            })
        },
        confirm() {
            let params = {
                importId: this.importId,
            }
            let url = '/baseinforoute/importRouteData'
            this.$http.post(url, params).then(
                data => {
                    if (data.code == 0) {
                        this.importStatus = 1
                        this.oncheck()
                    }
                },
                () => {
                    this.importStatus = -1
                }
            )
        },
    },
}
</script>
<style lang="scss" scoped="brand">
</style>
<style lang="scss">
</style>
