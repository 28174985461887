<template>
	<div>
		<jy-dialog type="formDouble" :title="title" :visible.sync="dialogVisible" custom-class="dialog_table">
			<div>
				<el-form :model="diaform" ref="dialogForm" :rules="rules">
					<el-row>
						<el-col :span="12">
							<el-form-item label="线路编号:" prop="routeInfoVO.routeNu" :label-width="formLabelWidth">
								<el-input v-model="diaform.routeInfoVO.routeNu"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="线路名称:" prop="routeInfoVO.cName" :label-width="formLabelWidth">
								<el-input v-model="diaform.routeInfoVO.cName"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="所属机构:" prop="orgNa" :label-width="formLabelWidth">
								<div @click="selectInstitutions">
									<el-input placeholder="请选择" v-model="diaform.orgNa"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="线路类型:" prop="routeInfoVO.rType" :label-width="formLabelWidth">
								<el-select v-model="diaform.routeInfoVO.rType" placeholder="请选择">
									<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="早高峰开始时间:" prop="routeInfoVO.mornPeakS" :label-width="formLabelWidth">
								<el-time-picker value-format="HH:mm" format = 'HH:mm' v-model="diaform.routeInfoVO.mornPeakS"  placeholder="选择时间"></el-time-picker>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="早高峰结束时间:" prop="routeInfoVO.mornPeakE" :label-width="formLabelWidth">
								<el-time-picker value-format="HH:mm" format = 'HH:mm' v-model="diaform.routeInfoVO.mornPeakE"  placeholder="选择时间"></el-time-picker>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="晚高峰开始时间:" prop="routeInfoVO.nightPeakS" :label-width="formLabelWidth">
								<el-time-picker value-format="HH:mm" format = 'HH:mm' v-model="diaform.routeInfoVO.nightPeakS"  placeholder="选择时间"></el-time-picker>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="晚高峰结束时间:" prop="routeInfoVO.nightPeakE" :label-width="formLabelWidth">
								<el-time-picker value-format="HH:mm" format = 'HH:mm' v-model="diaform.routeInfoVO.nightPeakE"  placeholder="选择时间"></el-time-picker>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<!-- <el-form-item label="所属城市:" prop="routeInfoVO.bCity" :label-width="formLabelWidth">
                                <el-input v-model="diaform.routeInfoVO.bCity"></el-input>
							</el-form-item> -->
							<el-form-item label="所属省市:" prop="routeInfoVO.provinceOptions" :label-width="formLabelWidth">
								<el-cascader :options="cityoptions" v-model="diaform.routeInfoVO.provinceOptions" @change="handleChange"></el-cascader>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="上行场站:" prop="routeInfoVO.uStationId" :label-width="formLabelWidth">
								<el-select v-model="diaform.routeInfoVO.uStationId" placeholder="请选择">
									<el-tag type="primary"  class="select-tag" @click="getNextStations">下一页</el-tag>
									<el-option v-for="item in stationIds" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="上行单程时间:" prop="routeInfoVO.uTime" :label-width="formLabelWidth">
								<el-input  v-model="diaform.routeInfoVO.uTime"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="上行单程距离:" prop="routeInfoVO.uMileage" :label-width="formLabelWidth">
								<el-input  v-model="diaform.routeInfoVO.uMileage"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="上行最小间隔站点数:" prop="routeInfoVO.uMinDI" :label-width="formLabelWidth">
								<el-input  v-model="diaform.routeInfoVO.uMinDI"><i slot="suffix" class="dw">个</i></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="上行最大间隔站点数:" prop="routeInfoVO.uMaxDI" :label-width="formLabelWidth">
								<el-input  v-model="diaform.routeInfoVO.uMaxDI"><i slot="suffix" class="dw">个</i></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="下行场站:" prop="routeInfoVO.dStationId" :label-width="formLabelWidth">
								<el-select v-model="diaform.routeInfoVO.dStationId" placeholder="请选择">
									<el-tag type="primary"  class="select-tag" @click="getNextStations">下一页</el-tag>
									<el-option v-for="item in stationIds" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="下行单程距离:" prop="routeInfoVO.dMileage" :label-width="formLabelWidth">
								<el-input  v-model="diaform.routeInfoVO.dMileage"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="下行最小间隔站点数:" prop="routeInfoVO.dMinDI" :label-width="formLabelWidth">
								<el-input  v-model="diaform.routeInfoVO.dMinDI" w><i slot="suffix" class="dw">个</i></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="下行最大间隔站点数:" prop="routeInfoVO.dMaxDI" :label-width="formLabelWidth">
								<el-input  v-model="diaform.routeInfoVO.dMaxDI"><i slot="suffix" class="dw">个</i></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="串车距离:" prop="routeInfoVO.crossDis" :label-width="formLabelWidth">
								<el-input  v-model="diaform.routeInfoVO.crossDis"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="大间隔距离:" prop="routeInfoVO.largeDis" :label-width="formLabelWidth">
								<el-input  v-model="diaform.routeInfoVO.largeDis"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="售票方式:" prop="routeInfoVO.ticketM" :label-width="formLabelWidth">
								<el-select v-model="diaform.routeInfoVO.ticketM" placeholder="请选择">
									<el-option v-for="item in ticketMList" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="运营类型:" prop="routeInfoVO.opType" :label-width="formLabelWidth">
								<el-select v-model="diaform.routeInfoVO.opType" placeholder="请选择">
									<el-option v-for="item in opTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="线路状态:" prop="routeInfoVO.state" :label-width="formLabelWidth">
								<el-select v-model="diaform.routeInfoVO.state" placeholder="请选择">
									<el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="线路总长:" prop="routeInfoVO.routeLen" :label-width="formLabelWidth">
								<el-input  v-model="diaform.routeInfoVO.routeLen"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-form-item label="备注:" prop="routeInfoVO.remark" :label-width="formLabelWidth">
						<el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="diaform.routeInfoVO.remark"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
				<el-button type="primary" @click="save">确 定</el-button>
			</div>
		</jy-dialog>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
	</div>
</template>
<script>
    import { mapMutations } from 'vuex'
	import {provinceAndCityData} from 'element-china-area-data'
	import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
	// let checkNo = (rule, value, callback) => {
	// 	let test = /^[0-9]{1,8}$/;
	// 	console.log(test.test(value));
	// 	if (!test.test(value)) {
	// 		callback(new Error("必须为8位或以下的数字"));
	// 	} else {
	// 		callback();
	// 	}
	// };
	export default {
		data() {
			return {
				// 对话框类型
				type: "",
				title: "",
				cityoptions: provinceAndCityData,
				stationIds: [],
				options: [
					{
						value: "0",
						label: "上下行",
					},
					{
						value: "1",
						label: "环形",
					},
				],
				ticketMList: [
					{
						value: "0",
						label: "人工售票"
					},{
						value: "1",
						label: "无人售票"
					}
				],
				opTypeList:[
					{
						value: "0",
						label: "非运营"
					},{
						value: "1",
						label: "运营"
					}
				],
				statusList: [
					{
						value: "0",
						label: "启用"
					},{
						value: "1",
						label: "禁用"
					}
				],

				diaform: {
					orgId: "",
					orgNa: "",
					routeInfoVO: {
						province: "",
						bCity: "",
						provinceOptions: [],
						cName: "",
						routeNu: "",
						remark: "",
						rType: "",
						uStationId: "",
						uTime: "",
						uMileage: "",
						uMinDI: "",
						uMaxDI: "",
						dStationId: "",
						dMileage: "",
						dMinDI: "",
						dMaxDI: "",
						crossDis: "",
						largeDis: "",
						ticketM: "",
						opType: "",
						state: "",
						routeLen: "",
						mornPeakS: "",
						mornPeakE: "",
						nightPeakS: "",
						nightPeakE: ""
					}
				},
				dialogVisible: false,
				formLabelWidth: "150px",
				dLabelWidth: "145px",
				pageIndex: 1,
				pageSize: 10,

				rules: {
					orgNa: [
							{
								required: true,
								message: "请选择所属机构",
								trigger: "change",
							},
					],
					routeInfoVO: {
						routeNu: [
							{
								required: true,
								message: "请输入线路编号",
								trigger: "blur",
							},
							// { validator: checkNo, trigger: "blur" },
						],
						cName: [
							{
								required: true,
								message: "请输入线路名称",
								trigger: "blur",
							},
						],
						provinceOptions: [
							{
								required: true,
								message: "请选择所在省市",
								trigger: "blur",
							},
						],
						rType: [
							{
								required: true,
								message: "请选择线路类型",
								trigger: "change",
							},
						],
						uMileage: [
							{
								required: true,
								message: "请输入上行单程距离",
								trigger: "blur",
							},
						],
						dMileage: [
							{
								required: true,
								message: "请输入下行单程距离",
								trigger: "blur",
							},
						],
						mornPeakS: [
							{
								required: true,
								message: "请选择早高峰开始时间",
								trigger: "blur",
							},
						],
						mornPeakE: [
							{
								required: true,
								message: "请选择早高峰结束时间",
								trigger: "blur",
							},
						],
						nightPeakS: [
							{
								required: true,
								message: "请选择晚高峰开始时间",
								trigger: "blur",
							},
						],
						nightPeakE: [
							{
								required: true,
								message: "请选择晚高峰结束时间",
								trigger: "blur",
							},
						],
						uMinDI: [
							{
								required: true,
								message: "请输入上行最小间隔站点数",
								trigger: "blur",
							},
						],
						uMaxDI: [
							{
								required: true,
								message: "请输入上行最大间隔站点数",
								trigger: "blur",
							},
						],
						dMinDI: [
							{
								required: true,
								message: "请输入下行最大间隔站点数",
								trigger: "blur",
							},
						],
						dMaxDI: [
							{
								required: true,
								message: "请输入下行最大间隔站点数",
								trigger: "blur",
							},
						],
					}
				},
			};
		},
		created() {
		},
		activated() {
		},
		watch: {
			dialogVisible(newview) {
				if (!newview) {
					this.resetForm("dialogForm");
					this.diaform.orgId = "";
				}
			},
		},
		components: {
			institutionsTree,
		},
		methods: {
            ...mapMutations(["resetTree"]),
			// 打开对话框
			init(option, type) {
				this.dialogVisible = true;
				this.type = type || "add";
				if (this.type == "update") {
					this.title = "编辑";
					this.$nextTick(() => {
						this.diaform = JSON.parse(JSON.stringify(option));
						this.getProvince(this.diaform.routeInfoVO)
					});
				} else {
					this.title = "新增";
					this.clean();
				}
				this.getStations();
			},
			getProvince(option) {
				let provinceAndCiti = []
				this.cityoptions.forEach(item => {
					if(item.label == option.province) {
						this.diaform.routeInfoVO.province = item.value
						provinceAndCiti.push(item.value)
						item.children.forEach(e=> {
							if(e.label == option.bCity) {
								this.diaform.routeInfoVO.bCity = e.value
								provinceAndCiti.push(e.value)
							}
						})
					}
				});
				this.diaform.routeInfoVO.provinceOptions = provinceAndCiti
			},
			handleChange(value) {
				if(value) {
					this.cityoptions.forEach(item => {
						if(item.value == value[0]) {
							this.diaform.routeInfoVO.province = item.label
							debugger
							item.children.forEach(e=> {
								if(e.value == value[1]) {
									this.diaform.routeInfoVO.bCity = e.label
								}
							})
						}
					});
					console.log(this.diaform.routeInfoVO)
				}
			},
			// 所属机构
			addInstitutions(data) {
				this.diaform.orgId = data.orgId;
				this.diaform.orgNa = data.orgNa;
			},
			// 组织
			selectInstitutions() {
				this.$refs.institutions.init();
			},
			// 重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			cancel() {
				this.dialogVisible = false;
				console.log("cancel");
			},
			clean() {
				this.diaform.orgId= "";
				this.diaform.orgNa= "";
				this.diaform.routeInfoVO.bCity= "";
				this.diaform.routeInfoVO.province= "";
				this.diaform.routeInfoVO.provinceOptions= [];
				this.diaform.routeInfoVO.cName= "";
				this.diaform.routeInfoVO.routeNu= "";
				this.diaform.routeInfoVO.remark= "";
				this.diaform.routeInfoVO.rType= "";
				this.diaform.routeInfoVO.uStationId= "";
				this.diaform.routeInfoVO.uTime= "";
				this.diaform.routeInfoVO.uMileage= "";
				this.diaform.routeInfoVO.uMinDI= "";
				this.diaform.routeInfoVO.uMaxDI= "";
				this.diaform.routeInfoVO.dStationId= "";
				this.diaform.routeInfoVO.dMileage= "";
				this.diaform.routeInfoVO.dMinDI= "";
				this.diaform.routeInfoVO.dMaxDI= "";
				this.diaform.routeInfoVO.crossDis= "";
				this.diaform.routeInfoVO.largeDis= "";
				this.diaform.routeInfoVO.ticketM= "";
				this.diaform.routeInfoVO.opType= "";
				this.diaform.routeInfoVO.state= "";
				this.diaform.routeInfoVO.routeLen= "";
				this.diaform.routeInfoVO.mornPeakS= "";
				this.diaform.routeInfoVO.mornPeakE= "";
				this.diaform.routeInfoVO.nightPeakS= "";
				this.diaform.routeInfoVO.nightPeakE= "";
			},
			save() {
				this.$refs.dialogForm.validate((valid) => {
					if (valid) {
						let url = "";
						if (this.type == "add") {
							url = "/route/info/add";
						} else {
							url = "/route/info/update";
						}

						//let option = this.diaform;
						let option = this.diaform.routeInfoVO;
						option.orgId = this.diaform.orgId;
						option.orgNa = this.diaform.orgNa;
						console.log(option)
						this.$http.post(url, option).then((res) => {
							console.log(res);
							this.$message({
								message: res.msg,
								type: "success",
							});
							this.dialogVisible = false;
                            this.resetTree()
							this.$emit("updateList");
						});
					} else {
						return false;
					}
				});
			},
			getStations() {
				let url = '/station/queryPage'
				let option = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
				}
				this.$http.post(url, option).then(({ detail }) => {
					console.log(detail)
					this.stationIds = detail.list.map(item=>{
						return {
							value: item.stationId,
							label: item.cName
						}
					})
				})
			},
			getNextStations() {
				this.pageIndex = (this.pageIndex) * this.pageSize+1;
				let url = '/station/queryPage'
				let option = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
				}
				this.$http.post(url, option).then(({ detail }) => {
					console.log(detail)
					if(detail.list.length > 0) {
						let list = detail.list.map(item=>{
							return {
								value: item.stationId,
								label: item.cName
							}
						})
						this.stationIds.concat(list);
					}
				})
			}
		},
	};
</script>
<style lang="scss" scoped>
	.select-tag {
		width: 96%;
		margin: 2%;
		text-align: center;
		cursor: pointer;
	}
	.dw {
		font-style: normal;
		//color: #000;
		font-weight: bold;
	}
</style>
