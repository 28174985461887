<template>
    <div>
        <jy-dialog type="table" :title="title" :visible.sync="dialogVisible" custom-class="dialog_table" :width="global.dialogTabelWidth">
            <div>
                <el-form :model="diaform" ref="dialogForm" :rules="rules">
                    <!-- <el-form-item prop="haveWeekend" :label-width="formLabelWidth">
                        <el-checkbox v-model="diaform.haveWeekend" true-label="1" false-label="0">区分工作日和周末</el-checkbox>
                    </el-form-item>-->
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="上行首班:" prop="upFirstDate" :label-width="formLabelWidth">
                                <el-time-picker v-model="diaform.upFirstDate" placeholder="选择时间" value-format="HH:mm" format="HH:mm"></el-time-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="上行末班:" prop="upLastDate" :label-width="formLabelWidth">
                                <el-time-picker v-model="diaform.upLastDate" placeholder="选择时间" value-format="HH:mm" format="HH:mm"></el-time-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- <el-row>
                        <el-col :span="12">
                            <el-form-item label="（周末）:" prop="upFirstWeekend" :label-width="formLabelWidth" v-if="isShow">
                                <el-time-picker v-model="diaform.upFirstWeekend" placeholder="选择时间" value-format="HH:mm" format="HH:mm">
                                </el-time-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="（周末）:" prop="upLastWeekend" :label-width="formLabelWidth" v-if="isShow">
                                <el-time-picker v-model="diaform.upLastWeekend" placeholder="选择时间" value-format="HH:mm" format="HH:mm">
                                </el-time-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>-->
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="下行首班:" prop="downFirstDate" :label-width="formLabelWidth">
                                <el-time-picker v-model="diaform.downFirstDate" placeholder="选择时间" value-format="HH:mm" format="HH:mm"></el-time-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="下行末班:" prop="downLastDate" :label-width="formLabelWidth">
                                <el-time-picker v-model="diaform.downLastDate" placeholder="选择时间" value-format="HH:mm" format="HH:mm"></el-time-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- <el-row>
                        <el-col :span="12">
                            <el-form-item label="（周末）:" prop="downFirstWeekend" :label-width="formLabelWidth" v-if="isShow">
                                <el-time-picker v-model="diaform.downFirstWeekend" placeholder="选择时间" value-format="HH:mm" format="HH:mm">
                                </el-time-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="（周末）:" prop="downLastWeekend" :label-width="formLabelWidth" v-if="isShow">
                                <el-time-picker v-model="diaform.downLastWeekend" placeholder="选择时间" value-format="HH:mm" format="HH:mm">
                                </el-time-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>-->
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="启用日期:" prop="startDate" :label-width="formLabelWidth">
                                <el-date-picker v-model="diaform.startDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions"></el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- <el-form-item label="备注:" prop="remark" :label-width="formLabelWidth">
                        <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="diaform.remark">
                        </el-input>
                    </el-form-item>-->
                </el-form>
            </div>
            <jy-table ref="multipleTable" :data="lists" tooltip-effect="dark" header-cell-class-name="bg_color" border style="width: 100%">
                <jy-table-column type="index" width="60" label="序号"></jy-table-column>
                <jy-table-column prop="createTime" label="历史设定时间" min-width="100"></jy-table-column>
                <jy-table-column prop="upFirstDate" label="上行首班" min-width="100"></jy-table-column>
                <!-- <jy-table-column
                        prop="upFirstWeekend"
                        label="上行首班(周末)"
                        min-width="120"
                    >
                    </jy-table-column>-->
                <jy-table-column prop="upLastDate" label="上行末班" min-width="100"></jy-table-column>
                <!-- <jy-table-column
                        prop="upLastWeekend"
                        label="上行末班(周末)"
                        min-width="120"
                    >
                    </jy-table-column>-->
                <jy-table-column prop="downFirstDate" label="下行首班" min-width="100"></jy-table-column>
                <!-- <jy-table-column
                        prop="downFirstWeekend"
                        label="下行首班(周末)"
                        min-width="120"
                    >
                    </jy-table-column>-->
                <jy-table-column prop="downLastDate" label="下行末班" min-width="100"></jy-table-column>
                <!-- <jy-table-column
                        prop="downLastWeekend"
                        label="下行末班(周末)"
                        min-width="120"
                    >
                    </jy-table-column>-->
                <jy-table-column prop="startDate" label="启用日期" min-width="100"></jy-table-column>
                <!-- <jy-table-column prop="remark" label="备注" min-width="200" class-name="align_left">
                    </jy-table-column>-->
            </jy-table>
            <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            title: '首末班发车时间设定',

            routeId: '',
            // 是否设置首末班时间
            isSetShiftTime: '1',

            isShow: false,

            diaform: {
                haveWeekend: '0',
                upFirstDate: '',
                upFirstWeekend: '',
                upLastDate: '',
                upLastWeekend: '',
                downFirstDate: '',
                downFirstWeekend: '',
                downLastDate: '',
                downLastWeekend: '',
                startDate: ''
                // remark: ''
            },
            lists: [{
                createTime: '',
                upFirstDate: '',
                upFirstWeekend: '',
                upLastDate: '',
                upLastWeekend: '',
                downFirstDate: '',
                downFirstWeekend: '',
                downLastDate: '',
                downLastWeekend: '',
                startDate: ''
                // remark: ''
            }],
            dialogVisible: false,
            formLabelWidth: '150px',

            rules: {
                // upFirstDate: [{
                //     required: true,
                //     message: '请选择上行首班时间',
                //     trigger: 'blur'
                // }],
                // upFirstWeekend: [{
                //     required: true,
                //     message: '请选择上行首班时间(周末)',
                //     trigger: 'blur'
                // }],
                // upLastDate: [{
                //     required: true,
                //     message: '请选择上行末班时间',
                //     trigger: 'blur'
                // }],
                // upLastWeekend: [{
                //     required: true,
                //     message: '请选择上行末班时间(周末)',
                //     trigger: 'blur'
                // }],
                // downFirstDate: [{
                //     required: true,
                //     message: '请选择下行首班时间',
                //     trigger: 'blur'
                // }],
                // downFirstWeekend: [{
                //     required: true,
                //     message: '请选择下行首班时间(周末)',
                //     trigger: 'blur'
                // }],
                // downLastDate: [{
                //     required: true,
                //     message: '请选择下行末班时间',
                //     trigger: 'blur'
                // }],
                // downLastWeekend: [{
                //     required: true,
                //     message: '请选择下行末班时间(周末)',
                //     trigger: 'blur'
                // }],
                startDate: [{
                    required: true,
                    message: '请选择启用日期',
                    trigger: 'blur'
                }]
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7
                }
            },
            // 分页
            pageIndex: 1,
            total: 100,
            pageSize: 10
        }
    },
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('dialogForm')
            }
        },
        'diaform.haveWeekend'(val) {
            if (val == '1') {
                this.isShow = true
            } else {
                this.isShow = false
                this.diaform.upFirstWeekend = ''
                this.diaform.upLastWeekend = ''
                this.diaform.downFirstWeekend = ''
                this.diaform.downLastWeekend = ''
            }
        }
    },
    methods: {
        // 打开对话框
        init(option) {
            this.dialogVisible = true
            this.routeId = option.routeId
            this.$nextTick(() => {
                // this.diaform = JSON.parse(JSON.stringify(option))
            })
            this.getlist()
        },
        // 获取历史数据
        getlist() {
            let url = 'baseinforoute/listHistory'
            let option = {
                routeId: this.routeId,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }
            this.$http.post(url, option).then(({ detail }) => {
                this.lists = detail.list
                this.total = detail.total
            })
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },

        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        save() {
            this.$refs.dialogForm.validate(valid => {
                if (valid) {
                    let url = '/baseinforoute/setShiftTime'
                    let option = {
                        ...this.diaform,
                        isSetShiftTime: this.isSetShiftTime,
                        routeId: this.routeId
                    }
                    this.$http.post(url, option).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                        // this.dialogVisible = false
                        this.getlist()
                        this.diaform.upFirstDate = ''
                        this.diaform.upLastDate = ''
                        this.diaform.downFirstDate = ''
                        this.diaform.downLastDate = ''
                        this.diaform.startDate = ''
                        this.$emit('updateList')
                    })
                } else {
                    return false
                }
            })
        },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.getlist()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.pageIndex = val
            this.getlist()
        }
    }
}

</script>
<style lang="scss" scoped></style>
